<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';

// Components
import Button from '@/Components/Button/Button.vue';

const route = inject('route');

const form = useForm({
    company_name: '',
    address: '',
    contact_person: '',
    tel: '',
    email: '',
});

const hasBeenSuccessful = ref(false);

const sendForm = () => {
    form.post(route('employers.recruitment-selection.apply'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess: () => (hasBeenSuccessful.value = true),
    });
};
</script>

<template>
    <div class="">
        <template v-if="hasBeenSuccessful">
            <h2 class="text-2xl font-bold uppercase">
                {{ $t('Thank you for your message!') }}
            </h2>
            <div class="text-4">
                {{ $t('We will be in touch shortly') }}
            </div>
        </template>
        <template v-else>
            <FormKit type="form" id="signup-form" @submit="sendForm" :actions="false">
                <FormKit :message-class="'text-white'"
                    type="text"
                    name="company_name"
                    :label="$t('Company name')+ '*'"
                    v-model="form.company_name"
                    :errors="form.errors.company_name"
                         validation="required"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit :message-class="'text-white'"
                    type="text"
                    name="address"
                    :label="$t('Address')+ '*'"
                    v-model="form.address"
                    :errors="form.errors.address"
                         validation="required"
                    :label-class="{
                        'text-blue': false,
                    }"
                />

                <FormKit :message-class="'text-white'"
                    type="text"
                    name="contact_person"
                    :label="$t('Contact person')+ '*'"
                    v-model="form.contact_person"
                    :errors="form.errors.contact_person"
                         validation="required"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit :message-class="'text-white'"
                    type="tel"
                    name="tel"
                    :label="$t('Phone')+ '*'"
                    validation="required|tel"
                    v-model="form.tel"
                    :errors="form.errors.tel"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit :message-class="'text-white'"
                    type="email"
                    name="email"
                    :label="$t('E-mail')+ '*'"
                    validation="required|email"
                    v-model="form.email"
                    :errors="form.errors.email"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <p class='text-sm font-medium'>
                    *verplicht veld
                </p>
                <Button
                    :processing="form.processing"
                    blue
                    :recentlySuccessful="form.recentlySuccessful"
                    text="Verzenden"
                    class="w-full mt-6"
                />
            </FormKit>
        </template>
    </div>
</template>
