<script setup>
import PublicLayout from '@/Layouts/PublicLayout.vue';
import { inject, ref } from 'vue';
import Img from '@/Components/Img.vue';
import RecruitementForm from '@/Components/JobOffers/Employers/RecruitementForm.vue';
import Checkmark from '@i/checkmark-colourable.svg?raw';
import Icon from '@/Components/Icon.vue';

const route = inject('route');

// Partials
const props = defineProps({
    query: Object,
    page: Object,
});



const whyFlexable = ref([
    'Jarenlange ervaring in de branche',
    'Ontzorging op advertenties',
    'Database met kandidaten',
    'Opdrachtgever wordt helemaal ontzorgd',
    'Veel grotere kans op een match door onze unieke werkwijze',
    'Content en propositie van hoge kwaliteit',
    'Eerlijk aanmeldproces. Kandidaten melden zich aan op eigen initiatief!',
    'Bijdrage in Employer Branding',
    '100% benadering van de arbeidsmarkt (on- en offline)',
]);


</script>

<template>
    <PublicLayout title="FLEXABLE | Job offers">


        <template v-for='block in page?.blocks'>

            <div v-if="block.component_name === 'job-offer-gradient-banner'" class="relative bg-gradient-to-r from-pink to-orange md:min-h-10 py-10">
                <div class="container">
                    <div class=" max-w-screen-md">
                        <h1 class='max-w-130 text-xl font-medium uppercase md:text-3xl xl:text-7xl h-9 sm:h-auto' v-html='block.content.title'>
                        </h1>
                        <h2 class="mt-4 font-semibold uppercase leading-6" v-html='block.content.description'>
                        </h2>
                    </div>
                </div>
            </div>


            <div v-if="block.component_name === 'job-offer-text-with-image'" class="relative lg:py-20 lg:text-left bg-white">
                <div class="container">
                    <div class="py-20 md:w-[70%] lg:py-0 text-blue">
                        <!--                    <h1 class="text-4xl sm:text-6xl xl:text-9xl font-black -mt-22 md:-mt-22 xl:-mt-24 uppercase mb-12">Werving</h1>-->
                        <h2 class="font-black uppercase text-4xl xl:text-6xl" v-html='block.content.title'></h2>
                        <div class="flex flex-col gap-5">

                        <div class="max-w-screen-lg text-lg min-h-30" v-html='block.content.description'>

                        </div>
                        </div>
                    </div>
                </div>
                <div class="inset-y-0 right-0 h-80 md:w-[30%] md:absolute md:h-auto">
                    <img v-if='block.media'
                         :src="block.media[0]?.src" :alt="block.media[0]?.alt_text"
                         :srcset="block.media[0]?.src_set"
                         class="h-full w-full object-cover rounded-tl-[4rem]"
                    />
                </div>
            </div>


            <!--        <div class="bg-white py-15 text-blue md:text-left">-->
            <!--            <div class="container relative flex flex-col">-->
            <!--                <div class="flex flex-col gap-5 rounded-xl">-->
            <!--                    <h2 class='text-3xl font-black uppercase lg:text-4xl xl:text-5xl'>-->
            <!--                        Selectie-->
            <!--                    </h2>-->
            <!--                    <p>Vervolgens zal het selectieproces bestaan uit de volgende onderdelen:</p>-->

            <!--                    <div class="divide-y divide-pink">-->
            <!--                        <div class="py-5">-->
            <!--                            <h2 class="max-w-screen-lg text-2xl font-black uppercase text-pink">Intakegesprek</h2>-->
            <!--                            <p>-->
            <!--                                Wanneer wij een geschikte kandidaat hebben gevonden bespreken we de wensen van de-->
            <!--                                kandidaat. Tijdens het intakegesprek wordt de werkervaring uitgebreid doorgesproken en-->
            <!--                                er wordt gekeken of de wensen van de kandidaat overeenkomen met de openstaande vacature-->
            <!--                                en vereisten van jou als opdrachtgever. Daarnaast wordt ingegaan op-->
            <!--                                karaktereigenschappen en competenties van de kandidaat.-->
            <!--                            </p>-->
            <!--                        </div>-->
            <!--                        <div class="py-5">-->
            <!--                            <h2 class="max-w-screen-lg text-2xl font-black uppercase text-pink">-->
            <!--                                Controle referenties-->
            <!--                            </h2>-->
            <!--                            <p>-->
            <!--                                Indien mogelijk worden opgegeven referenties van de kandidaat telefonisch gecontroleerd.-->
            <!--                            </p>-->
            <!--                        </div>-->

            <!--                        <div class="py-5">-->
            <!--                            <h2 class="max-w-screen-lg text-2xl font-black uppercase text-pink">-->
            <!--                                Voorstel Kandidaat-->
            <!--                            </h2>-->
            <!--                            <p>-->
            <!--                                Na het selectieproces beslist Flex@ble of de kandidaat voor de betreffende vacature aan-->
            <!--                                jou wordt voorgesteld. Aan hand van het CV, de eventuele referenties en de gevoerde-->
            <!--                                selectiegesprekken wordt door Flex@ble de kandidaat eerst per e-mail voorgesteld.-->
            <!--                                Vervolgens beslis jij of de betreffende kandidaat wordt uitgenodigd om bij jou op-->
            <!--                                gesprek te komen.-->
            <!--                            </p>-->
            <!--                        </div>-->

            <!--                        <div class="py-5">-->
            <!--                            <h2 class="max-w-screen-lg text-2xl font-black uppercase text-pink">-->
            <!--                                KENNISMAKING KANDIDAAT BIJ OPDRACHTGEVER-->
            <!--                            </h2>-->
            <!--                            <p>-->
            <!--                                Wanneer je hebt besloten een kandidaat uit te nodigen voor een kennismakingsgesprek,-->
            <!--                                ontvangen wij graag jouw beschikbaarheid en zullen wij dit gesprek met de kandidaat voor-->
            <!--                                jou inplannen.Indien gewenst kunnen wij bij dit gesprek aanwezig zijn.-->
            <!--                            </p>-->
            <!--                        </div>-->

            <!--                        <div class="py-5">-->
            <!--                            <h2 class="max-w-screen-lg text-2xl font-black uppercase text-pink">-->
            <!--                                AFRONDING EN PROEFDAG-->
            <!--                            </h2>-->
            <!--                            <p>-->
            <!--                                Indien de kennismaking met de kandidaat is bevallen en je wenst deze kandidaat een-->
            <!--                                proefdag te laten meelopen dan behoort dat tot de mogelijkheden.Wij kunnen eventueel-->
            <!--                                ondersteunen bij de afronding, maar gaan ervan uit dat jij met de kandidaat tot-->
            <!--                                overeenstemming komt.-->
            <!--                            </p>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </div>-->


            <div v-if="block.component_name === 'job-offer-contact'" class="bg-gradient-to-r py-5 from-pink to-orange">
                <div class="container relative flex flex-col py-20 md:py-15">
                    <div id="apply" class="absolute -top-30"></div>
                    <h1 class="text-4xl sm:text-6xl xl:text-9xl font-black -mt-27 md:-mt-22 xl:-mt-24 uppercase mb-12" v-html='block.content.title'>
                    </h1>

                    <div class="mx-auto w-full max-w-screen-lg text-white">
                        <div class="mb-8 text-lg">
                            <div class='mb-8' v-html='block.content.description'>

                            </div>

                            <div class='grid grid-cols-1 gap-4 md:grid-cols-2'>
                                <div v-for='usp in block.content.usps' class='flex gap-4'>
                                    <Icon :icon='Checkmark' class='h-6 w-6' />
                                    <div v-html='usp.title'>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            <RecruitementForm></RecruitementForm>
                        </div>
                    </div>
                </div>
            </div>

        </template>



    </PublicLayout>
</template>
